import React, { useEffect, useState } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid, Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
const SearchInputButton = importer('Components/Inputs/SearchInputButton')
const CentersActions = importer('Stores/Centers/Actions')
const { getCssColorFromDrupalColorType, setTextColor } = importer('Constant/Utils', null)
const { useScreenSize } = importer('Contexts/ResponsiveContextProvider', null)
import UseLocationPopup from '../UseLocationPopUp'
const { getZipcodeFromCoordinates } = importer('APIs/centers', null)
import { ReactComponent as LocationIcon } from '../../../assets/media/icons/location-icon.svg'
import Modal from '../Modal'
const FindYourCentreBanner = (props) => {
  const { isMobileWidth, isTabletWidth } = useScreenSize()
  const [searchError, setSearchError] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [submitted, setSubmitted] = useState(null)
  const { t } = useTranslation('FindYourCentreBanner')
  const [zipcode, setZipcode] = useState('')
  const [coordinates, setCoordinates] = useState({ lat: '', long: '' })
  const [longitude, setLongitude] = useState('')
  const [togglePopUp, setTogglePopUp] = useState(false)
  // const { t, i18n } = useTranslation('SearchInput')
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, failure)
    } else {
      // throw an error
      // console.log('Geolocation not supported')
    }
  }
  function success(position) {
    const latitude = position.coords.latitude
    const longitude = position.coords.longitude
    setCoordinates((prev) => ({ ...prev, lat: latitude, long: longitude }))
  }

  useEffect(() => {
    if (coordinates.lat && coordinates.long) {
      getZipcode(coordinates.lat, coordinates.long).then((res) => {
        const results = res?.data?.results
        if (res?.data?.results[0]) {
          for (let j = 0; j < results[0]?.address_components?.length; j++) {
            if (results[0]?.address_components[j]?.types[0] == 'postal_code') {
              setZipcode(results[0]?.address_components[j]?.short_name)
              setSearchInput(results[0]?.address_components[j]?.short_name)
            }
          }
        }
      })
    }
  }, [coordinates])

  const getZipcode = async (lat, long) => {
    const res = await getZipcodeFromCoordinates(lat, long)
    return res
  }
  function failure() {
    setTogglePopUp(true)
  }
  const {
    getCentersSearchData,
    centersSearchedData,
    centersSearchedDataFailure,
    locationState,
    field_component_title: componentTitle,
    field_title_color: componentTitleColor,
    field_component_bg_image: componentBackgroundImage,
    field_short_description: componentShortDescription,
    field_column_title_color: componentColumnTitleColor,
    field_component_mbg_image: componentMobileBackgroundImage,
    field_container_size: componentContainerSize
  } = props
  const navigate = useNavigate()

  useEffect(() => {
    /**
     * Add an event listener on body to check if the ID clicked has the ID 'search-input-error'.
     * If not, setSearchError to false
     */
    document.body.addEventListener('click', (e) => {
      if (e.target.id !== 'search-input-error') {
        setSearchError(false)
      }
    })
  }, [])

  const onSubmit = ({ search }) => {
    search = search ? search?.trim() : searchInput?.trim()
    if (!search) {
      return null
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        locationSearch: 'search submitted',
        searchTerm: search
      }
    })
    setSubmitted(true)
    getCentersSearchData(search)
  }

  useEffect(() => {
    if (centersSearchedData && centersSearchedData.length > 0 && submitted) {
      navigate(`/locations/${searchInput.trim()}?search=true`)
    } else if (centersSearchedData && centersSearchedData.length === 0 && submitted) {
      setSearchError(true)
    }
  }, [centersSearchedData])

  const handleOnChange = (value) => {
    setSearchInput(value)
  }

  return (
    <div
      className='find-your-centre-banner'
      style={{
        backgroundImage: isMobileWidth ? `url(${componentMobileBackgroundImage?.url})` : `url(${componentBackgroundImage?.url})`,
        width: componentContainerSize === 'small_width' && !isMobileWidth ? (!isTabletWidth ? '1008px' : '90%') : '100%',
        borderRadius: componentContainerSize === 'small_width' && !isMobileWidth && '20px',
        margin: componentContainerSize === 'small_width' && !isMobileWidth && '0 auto'
      }}
    >
      <div className='main-container'>
        <Grid className='find-your-centre-banner-grid'>
          <Grid.Row
            className='find-your-centre-banner-row'
            style={{
              margin: componentContainerSize === 'small_width' && !isMobileWidth && !isTabletWidth && '0 90px'
            }}
          >
            <Grid.Column computer={8} tablet={8} mobile={16} verticalAlign='middle' className='title'>
              <div dangerouslySetInnerHTML={{ __html: componentTitle?.value }} style={{ ...setTextColor(componentTitleColor) }} />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16} verticalAlign='middle'>
              <FinalForm
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className='fields icon-arrow'>
                      <Field
                        name='search'
                        render={({ input, meta }) => (
                          <SearchInputButton
                            fieldLabel={
                              <div
                                dangerouslySetInnerHTML={{ __html: componentShortDescription?.value }}
                                style={{ ...setTextColor(componentColumnTitleColor) }}
                              />
                            }
                            type='text'
                            {...input}
                            defaultValue={locationState || ''}
                            height={56}
                            error={searchError}
                            errorMessage={`${t('errorMessage1')} <a id='search-input-error' href='/about-biolife/contact-us'>${t(
                              'contactUs'
                            )}</a> ${t('errorMessage2')}`}
                            onBlur={() => {
                              input.onBlur()
                            }}
                            onChange={(e, val) => {
                              searchError ? setSearchError(false) : null, handleOnChange(val.value), input.onChange(val.value)
                            }}
                            userLocation={true}
                            value={searchInput}
                          />
                        )}
                      />
                    </Form.Group>
                  </Form>
                )}
              />
              <div className='useCurrentLocation'>
                <div className='locationIcon'>
                  <LocationIcon width={16} height={16} />
                </div>
                <a className='useCurrentLocationLink' onClick={handleLocationClick}>
                  {t(`useCurrentLocation`)}
                </a>
                <Modal
                  maxWidth={836}
                  maxWidthMobile={'87.7%'}
                  maxHeight={461}
                  maxHeightMobile={380}
                  isOpen={togglePopUp}
                  onClose={() => {
                    setTogglePopUp(false)
                  }}
                >
                  <UseLocationPopup
                    togglePopUp={togglePopUp}
                    title={t('locationTitle')}
                    description={t('locationDescription')}
                    buttonText={t('ok')}
                    setTogglePopUp={setTogglePopUp}
                  />
                </Modal>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centersSearchedData: state.centers?.getZipcodeSearchCentersSuccess?.data,
  centersSearchedDataFailure: state.centers?.getZipcodeSearchCentersFailure
})

const mapDispatchToProps = (dispatch) => ({
  getCentersSearchData: (searchStr) => {
    dispatch(CentersActions.getZipcodeSearchCenters(searchStr))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(FindYourCentreBanner)
